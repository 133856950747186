import { useEffect, useState } from "react";
import VAPTLandingComponent from "../../../../Components/Services/VAPTlandingPage/vaptLandingPage";
import SocHeader from "../../../../Components/Services/SocHeader/SocHeader";
import CylinderContentComponent from "../../../../Components/Services/CylinderComponent/CylinderContentComponent";
import HexagonalPage from "../../../../Components/Services/hexagon/HexagonalPage";
import CardWithNumber from "../../../../Components/Services/card/CardWithNumber";
import PageFooter from "../../../../Components/common/Footer/PageFooter";
import { useLocation, useNavigate } from "react-router-dom";
import useAxiosInstance from "../../../../Utils/UtilityFunctions/RequestConfig";
import { useDispatch } from "react-redux";
import { setLoadingState } from "../../../../Redux/Loading-Slice";
import GetStartedComponent from "../../../../Components/FormComponents/GetStarted/GetStartedComponent";
import apiConfig from "../../../../Utils/API/apiConfig";

const MobilePenTesting = () => {
  const navigate = useNavigate();

  const [vaptData, setVaptData] = useState([]);

  const [toc1, setToc1] = useState<any>();
  const [toc2, setToc2] = useState<any>();
  const [toc3, setToc3] = useState<any>();
  const [toc4, setToc4] = useState<any>();
  const [toc5, setToc5] = useState<any>();
  const [toc6, setToc6] = useState<any>();
  const [toc7, setToc7] = useState<any>();

  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const location = useLocation();

  const fetchVaptData = async () => {
    try {
      dispatch(setLoadingState(true));
      const response = await axiosInstance.get(
        apiConfig.VAPT.Mobile.getDetails.api
      );
      response.data.data.map((attr: any, index: number) => {
        const vaptData = attr?.attributes?.vapaMobile;
        setVaptData(vaptData);
      });
      dispatch(setLoadingState(false));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchVaptData();
  }, []);

  useEffect(() => {
    if (vaptData && vaptData.length > 0) {
      const one_toc = vaptData.filter((data: any) => data.sectionToc === 1);
      setToc1(one_toc[0]);

      const two_toc = vaptData.filter((data: any) => data.sectionToc === 2);
      setToc2(two_toc[0]);

      const three_toc = vaptData.filter((data: any) => data.sectionToc === 3);
      setToc3(three_toc[0]);

      const four_toc = vaptData.filter((data: any) => data.sectionToc === 4);
      setToc4(four_toc[0]);

      const five_toc = vaptData.filter((data: any) => data.sectionToc === 5);
      setToc5(five_toc[0]);

      const six_toc = vaptData.filter((data: any) => data.sectionToc === 6);
      setToc6(six_toc[0]);

      const seven_toc = vaptData.filter((data: any) => data.sectionToc === 7);
      setToc7(seven_toc[0]);
    }
  }, [vaptData]);
  return (
    <div className="flex bg-bluePrimary overflow-hidden w-full">
      <div className={`flex-col  homepageLayout enableBlur w-full`}>
        <VAPTLandingComponent
          heading={toc1?.Header?.title}
          description={toc1?.Header?.description}
          isLinearGrident={true}
          backgroundImage={`${process.env.REACT_APP_URL}${toc1?.Header?.image?.data?.attributes?.url}`}
          backgroundType={toc1?.Header?.image?.data?.attributes?.ext}
        />

        {toc2 && toc3 && (
          <SocHeader
            pageParagraph={toc2?.pageParagraph}
            pageHeader={toc2?.pageHeader}
            cardData={toc2?.Cards}
            HorizentailDetails={toc3?.Cards}
            headingText={[`${toc3?.pageHeader}`, ""]}
          />
        )}

        <CylinderContentComponent
          orientation="left"
          bulletPoints={null}
          content={toc4?.Cards}
          heading={toc4?.pageHeader}
          listView={false}
          useGradient={false}
          isLinearGrident={true}
        />

        {/* TRAPAZOID COMPONENT */}
        <HexagonalPage
          title={toc5?.heading}
          data={toc5?.hexagonal_detail?.data.attributes.HexagonalData}
        />
        <CardWithNumber
          data={toc5?.hexagonal_detail?.data.attributes.HexagonalData}
        />
        <CylinderContentComponent
          orientation="right"
          bulletPoints={toc6?.BulletPoints}
          content={toc6?.Cards}
          heading={toc6?.pageHeader}
          listView={false}
          bulletImage="triangle"
          isLinearGrident={true}
        />
        <GetStartedComponent data={toc7} pageRoute={location.pathname} />
        <PageFooter />
      </div>
    </div>
  );
};

export default MobilePenTesting;
